import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import DatePicker from "react-datepicker";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import AddProviderForm from "../../Nomenclature/Logistics/Providers/AddProviderForm";
import AddProductInNirForm from "./AddProductInNirForm";
import './ProductReception.scss'
import {selectValueOnFocus} from "../../../../utils/functions";

export default function AddProductReception() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()

    const [serie, setSerie] = useState('')
    const [number, setNumber] = useState(1)
    const [date, setDate] = useState(new Date())
    const [docType, setDocType] = useState('invoice')
    const [docDate, setDocDate] = useState(new Date())
    const [docNumber, setDocNumber] = useState('')
    const [provider, setProvider] = useState('')
    const [products, setProducts] = useState([])
    const [productsOptions, setProductsOptions] = useState([''])
    const [ingredientsOptions, setIngredientsOptions] = useState([])
    const [providersOptions, setProvidersOptions] = useState([])
    const [administrationsOptions, setAdministrationsOptions] = useState([])
    const [showModalAddProvider, setShowModalAddProvider] = useState(false)
    const [showModalAddProductInNir, setShowModalAddProductInNir] = useState(false)
    const [vats, setVats] = useState([])
    const [productIndex, setProductIndex] = useState(null)
    const [value, setValue] = useState(0)
    const [total, setTotal] = useState(0)
    const [vatValue, setVatValue] = useState(0)


    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        if (showModalAddProductInNir === false){
            let v=0, t=0, vv=0
            for (let i=0; i < products.length; i++){
                v += parseFloat(products[i].value)
                t += parseFloat(products[i].total)
                vv += parseFloat(products[i].vat_value)
            }
            setValue(parseFloat(v).toFixed(2))
            setTotal(parseFloat(t).toFixed(2))
            setVatValue(parseFloat(vv).toFixed(2))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalAddProductInNir])
    useEffect(() => {
        if (params.id !== 'add')
            getNirInfo()
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (showModalAddProvider === false)
            getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalAddProvider])
    useEffect(() => {
        if (provider.length > 7 && !providersOptions.find((item) => item.fiscal_code.includes(provider)) && loading === false) {
            setShowModalAddProvider(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider])

    const getNirInfo = async () => {
        axios.get(process.env.REACT_APP_API + '/nir/find/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setSerie(response.data.serie)
                setNumber(response.data.number)
                setDate(new Date(response.data.date))
                setDocType(response.data.doc_type)
                setDocDate(new Date(response.data.doc_date))
                setDocNumber(response.data.doc_number)
                setProvider(response.data.provider.fiscal_code)
                setProducts(response.data.products)
                setValue(response.data.value)
                setTotal(response.data.total)
                setVatValue(response.data.vat_value)

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/nir/info', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (params.id === 'add'){
                    setSerie(response.data.serie)
                    setNumber(response.data.number)
                }


                setProvidersOptions(response.data.providers)
                let temp2 = []
                response.data.ingredients.forEach((item) => {
                    temp2.push({
                        value: item.id,
                        label: item.title,
                        unit: item.unit,
                    })
                })
                setIngredientsOptions(temp2)
                let temp1 = []
                response.data.products.forEach((item) => {
                    temp1.push({
                        value: item.id,
                        label: item.title,
                        price: item.price,
                        unit: item.unit,
                        vat: item.vat,
                        type: item.type
                    })
                })
                setProductsOptions(temp1)
                let temp = []
                response.data.administrations.forEach((item) => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })


                setAdministrationsOptions(temp)
                getVats()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        if (provider.length === 0 || (provider.length > 0 && !providersOptions.find((item) => item.fiscal_code.includes(provider)))) {
            toast.error(intl.formatMessage({id: "errors.please_fill_fiscal_code_provider"}))
            return
        }
        if (docType === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_doc_type"}))
            return
        }
        if (docNumber === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_doc_number"}))
            return
        }

        if (serie === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_series_nir"}))
            return
        }
        if (products.length === 0) {
            toast.error(intl.formatMessage({id: "errors.please_add_products"}))
            return
        }

        setSaveLoading(true)
        await axios.post(process.env.REACT_APP_API + '/nir' + (params.id === 'add' ? '' : '/' + params.id), {
            serie: serie,
            number: number,
            date: date,
            doc_type: docType,
            doc_date: docDate,
            doc_number: docNumber,
            provider: providersOptions.find((item) => item.fiscal_code.includes(provider)).id,
            products: products,
            value: value,
            vat_value: vatValue,
            total: total,
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                    navigate(-1)
                } else {
                    if (response.data.error === 'duplicat') {
                        toast.error(intl.formatMessage({id: 'errors.exist_duplicat_nir'}))
                    }
                    if (response.data.error === 'quantity_edited') {
                        toast.error(intl.formatMessage({id: 'errors.nir_quantity_edited'}))
                    }
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.product_receptions'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card product_reception">

                        <div className={"header"}><p>{intl.formatMessage({id: 'nir.' + (params.id === 'add' ? 'add_product_reception' : 'edit_product_reception')})}</p></div>

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'nir.provider_fiscal_code'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"}
                                                   value={provider}
                                                   onChange={(e) => {
                                                       context.setContentUpdated(true)
                                                       setProvider(e.target.value)
                                                   }} required
                                                   onFocus={selectValueOnFocus}
                                                   className={"form-input"} /*disabled={parseInt(params.acquisition ?? 0) !== 0}*/
                                                   list="psi_list"
                                            />
                                            {
                                                provider.length > 7 && providersOptions.filter((item) => item.fiscal_code.includes(provider)).length === 0 ?
                                                    <datalist id="psi_list">
                                                        <select>
                                                            <option key={'new_provider'}
                                                                    value={provider}>{intl.formatMessage({id: 'nir.add_provider'})}
                                                            </option>
                                                        </select>
                                                    </datalist>
                                                    :
                                                    <datalist id="psi_list">
                                                        <select>
                                                            {
                                                                provider.length > 1 && providersOptions.filter((item) => item.fiscal_code.includes(provider)).map((option) => (
                                                                    <option key={option.id}
                                                                            value={option.fiscal_code}>{option.title}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </datalist>
                                            }

                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-4"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'nir.doc_type'})}<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[
                                                    {
                                                        value: 'invoice',
                                                        label: intl.formatMessage({id: 'nir.doc_type.invoice'})
                                                    },
                                                    {
                                                        value: 'receipt',
                                                        label: intl.formatMessage({id: 'nir.doc_type.receipt'})
                                                    },
                                                    {
                                                        value: 'fiscal_receipt',
                                                        label: intl.formatMessage({id: 'nir.doc_type.fiscal_receipt'})
                                                    },
                                                    {
                                                        value: 'without_doc',
                                                        label: intl.formatMessage({id: 'nir.doc_type.without_doc'})
                                                    },
                                                ]}
                                                required
                                                value={docType}
                                                onChange={(option) => {
                                                    context.setContentUpdated(true)
                                                    setDocType(option.value)
                                                }}
                                                isSearchable={true}
                                                isOptionDisabled={(option) => option.disabled}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-4"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'nir.doc_number'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} step={1} className={"form-input"} required
                                                   value={docNumber}
                                                   onFocus={selectValueOnFocus} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setDocNumber(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-4"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'nir.doc_date'})}<span
                                                className={"form-required"}>*</span></div>
                                            <DatePicker
                                                className={"form-input"}
                                                locale={moment.locale()}
                                                selected={docDate}
                                                maxDate={new Date()}
                                                dateFormat={"dd/MM/yyyy"}
                                                onChange={(date) => setDocDate(date)}
                                                requires
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-4"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'nir.nir_date'})}<span
                                                className={"form-required"}>*</span></div>
                                            <DatePicker
                                                className={"form-input"}
                                                locale={moment.locale()}
                                                selected={date}
                                                maxDate={new Date()}
                                                dateFormat={"dd/MM/yyyy"}
                                                onChange={(date) => setDate(date)}
                                                requires
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-4"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'nir.serie'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={serie}
                                                   onFocus={selectValueOnFocus} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setSerie(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-4"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'nir.number'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"number"} step={1} className={"form-input"} required
                                                   value={number}
                                                   onFocus={selectValueOnFocus} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setNumber(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    {products.length > 0 && <div className={"col-12"}>
                                        <div style={{overflowX: "scroll", marginTop: '20px'}}>
                                            <table style={{width: '100%'}}>
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{intl.formatMessage({id: 'general.type'})}</th>
                                                    <th>{intl.formatMessage({id: 'stock.menu.administration'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.product'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.unit_abbreviation'})}</th>
                                                    <th>{intl.formatMessage({id: 'general.vat'})}</th>
                                                    <th>{intl.formatMessage({id: 'general.qty'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.unit_price'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.value'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.total'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.vat_value'})}</th>
                                                    <th>{intl.formatMessage({id: 'menus.products.price'})}</th>
                                                    <th>{intl.formatMessage({id: 'nir.addition_percent'})}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    products.map((item, i) => (
                                                        <tr key={i} style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                setProductIndex(i)
                                                                setShowModalAddProductInNir(true)
                                                            }}
                                                        >
                                                            <td>{i + 1}</td>
                                                            <td>{intl.formatMessage({id: 'menus.products.type.' + item.type})}</td>
                                                            <td>{administrationsOptions.find((item2) => item2.value === item.administration).label}</td>
                                                            <th scope="row">{item.product_name}</th>
                                                            <td>{intl.formatMessage({id: 'general.unit.' + item.unit})}</td>
                                                            <td>{item.vat_percent}%</td>
                                                            <td>{item.qty}</td>
                                                            <td>{item.unit_price}</td>
                                                            <td>{item.value}</td>
                                                            <td>{item.total}</td>
                                                            <td>{item.vat_value}</td>
                                                            <td>{item.price}</td>
                                                            <td>{item.addition_percent}%</td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <td colSpan={4}></td>
                                                    <td colSpan={9}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>{intl.formatMessage({id: 'nir.value'})}</td>
                                                    <td colSpan={9} style={{textAlign: "end"}}>{value}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>{intl.formatMessage({id: 'nir.vat_value'})}</td>
                                                    <td colSpan={9} style={{textAlign: "end"}}>{vatValue}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>{intl.formatMessage({id: 'nir.total'})}</td>
                                                    <td colSpan={9} style={{textAlign: "end"}}>{total}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    <div className={"col-12"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            style={{width: '100%'}}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'nir.add_product'})}
                                            onClick={() => {
                                                setProductIndex(null)
                                                setShowModalAddProductInNir(true)
                                            }}
                                            type={'button'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                    <Button
                                        className={"btn-light mt-2"}
                                        icon={"fa-solid fa-chevron-left"}
                                        value={intl.formatMessage({id: 'general.buttons.back'})}
                                        onClick={() => {

                                            context.setLeavePage(true)
                                            context.setNextLink('/product-receptions')
                                        }}
                                    />
                                </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-secondary mt-2 ms-auto"}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'general.buttons.' + (params.id === 'add' ? 'add' : 'save')})}
                                            disabled={saveLoading}
                                            loading={saveLoading}
                                            type={'submit'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <ModalComp
                title={intl.formatMessage({id: 'nir.add_provider'})}
                isOpen={showModalAddProvider}
                onRequestClose={() => setShowModalAddProvider(false)}
                className={"subscription-limit change-currency"}
                styleContent={{height: "fit-content"}}
            >

                <div className={"content"} style={{height: "unset", maxHeight: "unset"}}>
                    <AddProviderForm isModal={true} fiscalCodeDefault={provider}
                                     setShowModal={setShowModalAddProvider}/>
                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'nir.' + (productIndex === null ? 'add_product' : 'edit_product')})}
                isOpen={showModalAddProductInNir}
                onRequestClose={() => setShowModalAddProductInNir(false)}
                className={"subscription-limit change-currency"}
                styleContent={{height: "fit-content", width: '80vw', maxWidth: "unset"}}
            >

                <div className={"content"} style={{height: "unset", maxHeight: "unset"}}>
                    <AddProductInNirForm productsOptions={productsOptions}
                                         administrations={administrationsOptions}
                                         ingredientsOptions={ingredientsOptions} productIndex={productIndex}
                                         vats={vats} getData={getData} products={products} setProducts={setProducts}
                                         setShowModalAddProductInNir={setShowModalAddProductInNir}
                    />
                </div>
            </ModalComp>

        </div>
    );
}

