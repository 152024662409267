import {useIntl} from "react-intl";
import {eventSheet} from "../../../../../../utils/constants";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import DatePicker from "react-datepicker";
import './EventSheet.scss';
import {toast} from "react-toastify";
import Button from "../../../../../../components/elements/Button/Button";
import TabSwitch from "../../../../../../components/elements/TabSwitch/TabSwitch";
import moment from "moment";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import CustomSwitch from "../../../../../../components/elements/Switch/CustomSwitch";
import Selector from "../../../../../../components/elements/Selector/Selector";
import PriceComponent from "./PriceComponent";
import CustomInput from "../../../../../../components/elements/Input/Input";

export default function EventSheet() {

    const navigate = useNavigate()
    const intl = useIntl();
    const inputRef = useRef()
    const jwt = localStorage.getItem('jwt')
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    let params = useParams();
    const [event, setEvent] = useState()
    const [timeStart, setTimeStart] = useState(null)
    const [sheet, setSheet] = useState()
    const [savedSheet, setSavedSheet] = useState()
    const [products, setProducts] = useState([])
    const [taxes, setTaxes] = useState([])
    const [loading, setLoading] = useState(true)
    const [display, setDisplay] = useState([])
    const [currentKey, setCurrentKey] = useState()
    const [currentFieldKey, setCurrentFieldKey] = useState()
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalContent, setModalContent] = useState([])
    const [currentProducts, setCurrentProducts] = useState([])
    const [modalProductsIsOpen, setModalProductsIsOpen] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [providers, setProviders] = useState([])
    const [activations, setActivations] = useState([])
    const [staffs, setStaffs] = useState([])
    const [file, setFile] = useState(null)
    const [fileUrl, setFileUrl] = useState('')

    const [modalExportDoc, setModalExportDoc] = useState(false)
    const [loadingDownloadDoc, setLoadingDownloadDoc] = useState(false)
    const [includeInDoc, setIncludeInDoc] = useState([])
    const {context, ended} = useOutletContext()


    useEffect(() => {
        if (access) {
            getStaff()
            getProviders()
            getProducts()
            getActivations()
            getTaxes()
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var defaultSheet = {};
                var defaultDisplay = [];
                setTimeStart(new Date(response.data.start))
                setSavedSheet({...response.data.sheet})

                Object.keys(eventSheet[response.data.event_type]).forEach(key => {
                    defaultSheet[key] = response.data.sheet && response.data.sheet[key] ? response.data.sheet[key] : {}

                    Object.keys(eventSheet[response.data.event_type][key]).forEach(fieldKey => {
                        switch (eventSheet[response.data.event_type][key][fieldKey].type) {
                            case 'time':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = new Date(response.data.sheet[key][fieldKey]);
                                }
                                break
                            case 'time_menu':
                                response.data.food_menu !== null && response.data.food_menu.content && response.data.food_menu.content.forEach((dish, i) => {
                                    if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey + '_' + response.data.food_menu.id + '_' + i] !== undefined) {
                                        defaultSheet[key][fieldKey + '_' + response.data.food_menu.id + '_' + i] = new Date(response.data.sheet[key][fieldKey + '_' + response.data.food_menu.id + '_' + i]);
                                    }
                                })
                                break
                            case 'text':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = '';
                                }
                                break
                            case 'select':
                            case 'select_from_list':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                }
                                break
                            case 'tab-switch':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = eventSheet[response.data.event_type][key][fieldKey].values['false'];
                                }
                                break
                            case 'on-off':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = false;
                                }
                                break
                            case 'checkbox':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                } else {
                                    defaultSheet[key][fieldKey] = false;
                                }
                                break
                            case 'number':

                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = parseInt(response.data.sheet[key][fieldKey]);
                                } else {
                                    if (fieldKey === 'number_chairs' || fieldKey === 'guests_number') {
                                        defaultSheet[key][fieldKey] = response.data.number_of_guests
                                    } else
                                        defaultSheet[key][fieldKey] = 0;
                                }


                                break
                            case 'button':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                }
                                break
                            case 'upload':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = process.env.REACT_APP_API + response.data.sheet[key][fieldKey].url;
                                    setFileUrl(defaultSheet[key][fieldKey])
                                }
                                break
                            case 'select_staff':
                            case 'select_provider':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                }
                                break
                            case 'select_activation':
                            case 'product_btl':
                            case 'input_with_options':
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = response.data.sheet[key][fieldKey];
                                }
                                break
                            default:
                                if (response.data.sheet && response.data.sheet[key] && response.data.sheet[key][fieldKey] !== undefined) {
                                    defaultSheet[key][fieldKey] = parseInt(response.data.sheet[key][fieldKey]);
                                }
                                break

                        }
                    })
                })
                Object.keys(eventSheet[response.data.event_type]).forEach(key => {
                    defaultDisplay.push({
                        category: key,
                        display: false
                    })
                })

                setDisplay(defaultDisplay)
                setSheet({...defaultSheet})

                setEvent(response.data)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setProducts(response.data.products ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getActivations = async () => {
        axios.post(process.env.REACT_APP_API + '/activations/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.activations.forEach((activation) => {
                    temp.push({
                        value: activation.id,
                        label: activation.title,
                    })
                })
                setActivations(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getProviders = async () => {
        axios.post(process.env.REACT_APP_API + '/providers/list/0', {search: '', category: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.providers.forEach((provider) => {
                    temp.push({
                        value: provider.id,
                        label: provider.title,
                    })
                })
                setProviders(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getStaff = async () => {
        axios.post(process.env.REACT_APP_API + '/staff-users/list/0', {search: '', category: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.staff.forEach((staff) => {
                    temp.push({
                        value: staff.id,
                        label: staff.name,
                    })
                })
                setStaffs(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTaxes = async () => {
        axios.get(process.env.REACT_APP_API + '/taxes', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setTaxes(response.data)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    const updateSheet = (key, fieldKey, value) => {
        let sheet_temp = {...sheet};
        if (!sheet_temp[key]) {
            sheet_temp[key] = {};
        }
        sheet_temp[key][fieldKey] = value;
        context.setContentUpdated(true)
        setSheet(sheet_temp)
    }


    const save = async (e, key) => {
        e.preventDefault();

        let payload = {
            sheet: {}
        }
        payload.sheet[key] = sheet[key]

        let formData = new FormData()
        if (file) {
            formData.append('timetable', file)
        }
        formData.append('data', JSON.stringify(payload))
        await axios.put(process.env.REACT_APP_API + '/events/sheet/' + params.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                context.setContentUpdated(false)
                let start = moment(timeStart).startOf('day');
                if (timeStart) {
                    start.hour(moment(timeStart).hour());
                    start.minute(moment(timeStart).minute());
                }

                payload = {
                    start: start,
                }
                await axios.put(process.env.REACT_APP_API + '/events/details/' + params.id, {data: JSON.stringify(payload)}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                    .then(async (response) => {
                        toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    })
                    .catch(err => {
                        errorParser(err, intl)
                    })
            })
            .catch(err => {
                errorParser(err, intl)
            })


    }

    const displayField = (field, key) => {
        if (field.conditions === undefined || field.conditions.length === 0) {
            return true;
        }


        let display = true;
        field.conditions.forEach((condition) => {
            if (sheet && sheet[key] && sheet[key][condition.field] !== undefined) {
                switch (condition.condition) {
                    case 'eq':
                        if (!(sheet[key][condition.field] === condition.value))
                            display = false;
                        break
                    case 'neq':
                        if (!(sheet[key][condition.field] !== condition.value))
                            display = false
                        break
                    case 'or':
                        if (!(sheet[key][condition.field] === condition.value_1 || sheet[key][condition.field] === condition.value_2))
                            display = false
                        break

                    default:
                        display = false
                        break
                }
            } else {
                display = false;
            }
        })
        return display;

    }

    const getOptions = (select, category) => {
        let options = [];

        let results = null;
        if (select === 'products') {
            results = products.filter((product) => product.category.slug === category);
            if (results.length > 0) {
                results.forEach((result) => {
                    options.push({
                        value: result.id,
                        label: result.title,
                        vat: result.vat.value,
                        image: result.image
                    })
                })
            }
        }
        if (select === 'taxes') {
            taxes.forEach((result) => {
                let event_types = result.event_types
                event_types = event_types.filter(type => type.title === category)
                if (event_types && event_types.length > 0) {
                    options.push({
                        value: result.id,
                        label: result.title,
                        type: result.type,
                        percent: result.percent,
                        percent_of: result.percent_of,
                    })
                }
            })

        }
        if (select === 'list') {
            category.forEach((item) => {
                options.push({
                    value: item.value,
                    label: intl.formatMessage({id: item.label}),
                })
            })
        }

        return options;

    }

    const updateDisplay = (e, key) => {
        e.preventDefault();
        var defaultDisplay = [...display];
        let result = defaultDisplay.find((display) => display.category === key)
        if (result) {
            const index = defaultDisplay.map(function (e) {
                return e.category;
            }).indexOf(result.category);

            if (index > -1) {
                result.display = !result.display;
                defaultDisplay[index] = result
            }
        }
        setDisplay(defaultDisplay)
    }

    const exportSheet = () => {
        setLoadingDownload(true)
        axios.post(process.env.REACT_APP_API + '/event-sheet-excel/' + params.id, {language: localStorage.getItem('language') ?? 'ro'}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }})      .then(response => {
            console.log(response)
            if (window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'openLink',
                    url: response.data.file  ,
                }))
            }else{
                var a = document.createElement('a');
                a.href = response.data.file;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }
            setLoadingDownload(false)
        })
            .catch(e => console.log(e))

    }
    const exportSheetDoc = () => {
        setLoadingDownloadDoc(true)

        axios.post(process.env.REACT_APP_API + '/event-sheet-doc/' + params.id, {language: localStorage.getItem('language') ?? 'ro', include: includeInDoc}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }})      .then(response => {
            console.log(response)
            if (window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'openLink',
                    url: response.data.file  ,
                }))
            }else{
                var a = document.createElement('a');
                a.href = response.data.file;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }
            setLoadingDownloadDoc(false)
            setModalExportDoc(false)
        })
            .catch(e => console.log(e))
    }

    return (
        <div className={"event-sheet"}>

            {
                window.alityAppView &&
                <div className={"col-12"}>
                    <Button
                        className={"btn-light mt-2"}
                        icon={"fa-solid fa-chevron-left"}
                        value={intl.formatMessage({id: 'general.buttons.back'})}
                        onClick={() => {

                            context.setLeavePage(true)
                            context.setNextLink('/calendar')
                        }}
                    />
                </div>
            }
            <Button
                className={"btn-secondary mb-2 ms-auto "}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.buttons.download'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportSheet()}
            />
            <Button
                className={"btn-secondary mb-2 ms-2 "}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.buttons.download_doc'})}
                type={"button"}

                loading={loadingDownloadDoc}
                disabled={loadingDownloadDoc}
                onClick={() => setModalExportDoc(true)}
            />

            {
                Object.keys(eventSheet[event.event_type]).map(key => {
                    var elements = eventSheet[event.event_type][key]
                    return (<div className="card mb-3"
                                 key={key + '-card-' + (sheet && sheet[key] ? Object.keys(sheet[key]).length : key)}>

                            <div className={"header"} onClick={(e) => updateDisplay(e, key)}>
                                <p>
                                    {intl.formatMessage({id: 'events.sheet.' + key})}
                                </p>
                                <p className={"ms-auto"}>
                                    <i className={"icon fa-solid fa-angle-down"}></i>
                                </p>
                            </div>
                            {display && display.find((display) => display.category === key).display &&
                                <form onSubmit={(e) => save(e, key)}>

                                    <div className={"content"}>
                                        <div className={"row"}>
                                            {
                                                // eslint-disable-next-line
                                                Object.keys(elements).map(fieldKey => {
                                                        if (displayField(elements[fieldKey], key)) {
                                                            switch (elements[fieldKey].type) {
                                                                case 'time_menu':
                                                                    let menus = [];
                                                                    let items = [];

                                                                    switch (fieldKey) {
                                                                        case 'service_time_table_staff':
                                                                            menus = event.food_menu_to_events.filter(item => item.type === 'staff');
                                                                            break;
                                                                        case 'service_time_table_children':
                                                                            menus = event.food_menu_to_events.filter(item => item.type === 'children');
                                                                            break;
                                                                        default:
                                                                            menus = event.food_menu_to_events.filter(item => item.type === 'normal');


                                                                    }
                                                                    for (let i = 0; i < menus.length; i++) {
                                                                        for (let j = 0; j < menus[i].food_menu.items.length; j++)
                                                                            items.push({
                                                                                title: menus[i].food_menu.items[j].title,
                                                                                menu: {
                                                                                    id: menus[i].food_menu.id,
                                                                                    title: menus[i].food_menu.title
                                                                                }
                                                                            })
                                                                    }

                                                                    return menus.length > 0 && items.length > 0 ? items.map((dish, i) => (
                                                                            <div
                                                                                className={(items.length === 1 ? 'col-12' : items.length === 2 ? 'col-6' :
                                                                                    items.length === 3 ? 'col-4' : items.length === 4 ? 'col-3' : 'col-12') + ' ' + elements[fieldKey].class}
                                                                                key={fieldKey + '_' + i}>
                                                                                <div className={"form-control"}>
                                                                                    <div
                                                                                        className={"form-label"}>{dish.menu.title}: {dish.title}</div>
                                                                                    <DatePicker
                                                                                        timeIntervals={15}
                                                                                        disabled={!access || ended}
                                                                                        timeCaption={intl.formatMessage({id: 'events.time'})}
                                                                                        selected={sheet && sheet[key] && sheet[key][fieldKey + '_' + dish.menu.id + '_' + i] ? new Date(sheet[key][fieldKey + '_' + dish.menu.id + '_' + i]) : ''}
                                                                                        onChange={(date) => updateSheet(key, fieldKey + '_' + dish.menu.id + '_' + i, date)}
                                                                                        showTimeSelectOnly showTimeSelect
                                                                                        dateFormat={"HH:mm"} timeFormat="HH:mm"
                                                                                        className={"form-input"}/>

                                                                                </div>
                                                                            </div>
                                                                        )) :
                                                                        <div
                                                                            className={"col-12 form-control " + elements[fieldKey].class}>
                                                                            <div className={"alert alert-warning mb-3"}>
                                                                                {intl.formatMessage({id: 'events.sheet.general.error_no_food_menu'})}
                                                                            </div>
                                                                            {
                                                                                access && !ended &&
                                                                                <Button
                                                                                    className={"btn-primary d-flex align-items-center mb-3 w-100 justify-content-center"}
                                                                                    value={intl.formatMessage({id: 'event.sheet.set_food_menu'})}
                                                                                    onClick={() => navigate('/events/' + params.id + '/food-menu')}
                                                                                />}
                                                                        </div>
                                                                case 'time':
                                                                    return (<div className={elements[fieldKey].class}
                                                                                 key={fieldKey}>
                                                                        <div className={"form-control"}>
                                                                            <div
                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                            <DatePicker
                                                                                timeIntervals={15}
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                                timeCaption={intl.formatMessage({id: 'events.time'})}
                                                                                selected={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                onChange={(date) => {
                                                                                    updateSheet(key, fieldKey, date)
                                                                                }}
                                                                                minTime={
                                                                                    elements[fieldKey].minTime && elements[fieldKey].minTime === true &&
                                                                                    sheet && sheet.general && sheet.general.final_hour ?
                                                                                        moment(sheet.general.final_hour).toDate() : moment(timeStart).startOf('day').toDate()}
                                                                                maxTime={elements[fieldKey].maxTime && elements[fieldKey].maxTime === true ?
                                                                                    moment(timeStart).toDate() : moment(timeStart).endOf('day').toDate()}
                                                                                showTimeSelectOnly showTimeSelect
                                                                                dateFormat={"HH:mm"} timeFormat="HH:mm"
                                                                                disabled={!access || ended}
                                                                                className={"form-input"}/>

                                                                        </div>
                                                                    </div>)
                                                                case 'number':
                                                                    if (fieldKey === 'menus_number') {
                                                                        let content = [];
                                                                        // eslint-disable-next-line
                                                                        {
                                                                            (() => {
                                                                                event.food_menu_to_events.forEach(async (item) => {
                                                                                    content.push(<div
                                                                                        className={item.food_menu.title + ' d-flex ' + elements[fieldKey].class}
                                                                                        key={fieldKey + '_' + item.type + '_' + item.food_menu.id}
                                                                                        style={{alignItems: 'end'}}>
                                                                                        <div className={"form-control"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div
                                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_' + item.type})
                                                                                                + ': ' + item.food_menu.title}</div>
                                                                                         <CustomInput
                                                                                             type={"number"}
                                                                                             disabled={!access || ended}
                                                                                             className={"form-input"}
                                                                                             onChange={(value) => {
                                                                                             updateSheet(key, fieldKey + '_' + item.type + '_' + item.food_menu.id, parseInt(value))
                                                                                         }}
                                                                                             key={''}
                                                                                             initValue={savedSheet && savedSheet[key] && savedSheet[key][fieldKey + '_' + item.type + '_' + item.food_menu.id] ?
                                                                                                 savedSheet[key][fieldKey + '_' + item.type + '_' + item.food_menu.id] : ''}


                                                                                         />
                                                                                        </div>
                                                                                    </div>)
                                                                                })
                                                                            })()
                                                                        }
                                                                        return content

                                                                    } else if (fieldKey === 'drink_menus_number') {
                                                                        let content = [];
                                                                        // eslint-disable-next-line
                                                                        {
                                                                            (() => {
                                                                                event.drink_menu_to_events.forEach(async (item) => {
                                                                                    content.push(<div
                                                                                        className={item.drink_menu.title + ' d-flex ' + elements[fieldKey].class}
                                                                                        key={fieldKey + '_' + item.type + '_' + item.drink_menu.id}
                                                                                        style={{alignItems: 'end'}}>
                                                                                        <div className={"form-control"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div
                                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})
                                                                                                + ': ' + item.drink_menu.title}</div>
                                                                                            <CustomInput
                                                                                                type={"number"}
                                                                                                disabled={!access || ended}
                                                                                                className={"form-input"}
                                                                                                onChange={(value) => {
                                                                                                    updateSheet(key, fieldKey + '_' + item.drink_menu.id, parseInt(value))
                                                                                                }}
                                                                                                key={''}
                                                                                                initValue={savedSheet && savedSheet[key] && savedSheet[key][fieldKey + '_' + item.drink_menu.id] ?
                                                                                                    savedSheet[key][fieldKey + '_' + item.drink_menu.id] : ''}
                                                                                            />
                                                                                        </div>
                                                                                    </div>)
                                                                                })
                                                                            })()
                                                                        }
                                                                        return content

                                                                    } else {
                                                                        return (<div
                                                                            className={'d-flex ' + elements[fieldKey].class}
                                                                            key={fieldKey} style={{alignItems: 'end'}}>
                                                                            <div className={"form-control"} style={{
                                                                                width: '100%',
                                                                                justifyContent: 'end'
                                                                            }}>
                                                                                <div
                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                <input type={"number"}
                                                                                       value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                       onFocus={(event) => event.target.select()}
                                                                                       onClick={(event) => event.target.select()}
                                                                                       min={0}
                                                                                       disabled={!access || ended}
                                                                                       onChange={(e) => updateSheet(key, fieldKey, parseInt(e.target.value))}
                                                                                       className={"form-input"}
                                                                                />
                                                                            </div>
                                                                        </div>)
                                                                    }
                                                                case 'price':
                                                                    return (
                                                                        <PriceComponent
                                                                            classTitle={elements[fieldKey].class}
                                                                            fieldKey={fieldKey}
                                                                            firstKey={key}
                                                                            currency={event.company.currency}
                                                                            disabled={!access || ended}
                                                                            updateSheet={updateSheet}
                                                                            initialValue={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : 0}
                                                                        />

                                                                        /*   <div
                                                                           className={'d-flex ' + }
                                                                           key={fieldKey} style={{alignItems: 'end'}}>
                                                                           <div className={"form-control"}
                                                                                style={{width: '100%', justifyContent: 'end'}}>
                                                                               <div
                                                                                   className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>


                                                                               <div className={"price-input-container"}>
                                                                                   <p>{intl.formatMessage({id: 'settings.currency.' + event.company.currency})}
                                                                                   </p>
                                                                                   <input type={"number"}
                                                                                          key={fieldKey}
                                                                                          defaultValue={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : 0}
                                                                                          //onFocus={(event) => event.target.select()}
                                                                                          disabled={!access || ended}
                                                                                         // onClick={(event) => event.target.select()}
                                                                                          min={0}
                                                                                          onChange={(e) => {
                                                                                              updateSheet(key, fieldKey, e.target.value)
                                                                                            //  e.target.select()
                                                                                          }}
                                                                                          className={"form-input"}
                                                                                   />
                                                                               </div>
                                                                           </div>
                                                                       </div>*/)
                                                                case 'text':
                                                                    return (<div
                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                        key={fieldKey} style={{alignItems: 'end'}}>
                                                                        <div className={"form-control"}
                                                                             style={{width: '100%', justifyContent: 'end'}}>
                                                                            <div
                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                            <input type={"text"}
                                                                                   value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                   disabled={!access || ended}
                                                                                   onChange={(e) => updateSheet(key, fieldKey, e.target.value)}
                                                                                   className={"form-input"}
                                                                            />
                                                                        </div>
                                                                    </div>)
                                                                case 'on-off':
                                                                    return (<div className={elements[fieldKey].class}
                                                                                 key={fieldKey}>
                                                                            <div className={"form-control"}>
                                                                                <div
                                                                                    className={"form-label form-label-switch"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>

                                                                                <CustomSwitch
                                                                                    checked={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : false}
                                                                                    disabled={!access || ended}
                                                                                    onChange={(e) => {
                                                                                        updateSheet(key, fieldKey, e)
                                                                                    }}>

                                                                                </CustomSwitch>

                                                                                {/* <Switch checked={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : false}
                                                                                        onColor={'#6C5DD3'} height={20} width={50} onChange={(e) => updateSheet(key, fieldKey, e)}
                                                                                        uncheckedIcon={true} checkedIcon={true} // onColor
                                                                                ></Switch>*/}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                case 'select':
                                                                    return (<div
                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                        key={fieldKey} style={{alignItems: 'end'}}>
                                                                        <div style={{width: '100%'}}
                                                                             className={"form-control"}>
                                                                            <div
                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.product'})}{
                                                                                sheet && sheet[key] && sheet[key][fieldKey] ?
                                                                                    (': ' + products.find((product) => product.id === sheet[key][fieldKey]).title) : ''}

                                                                            </div>
                                                                            {access && !ended &&
                                                                                <Button
                                                                                    className={"btn-light btn-edit ms-auto"}
                                                                                    icon={"fa-solid fa-pen"}
                                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                                    onClick={() => {
                                                                                        setCurrentKey(key)
                                                                                        setCurrentFieldKey(fieldKey)
                                                                                        setCurrentProducts(getOptions(elements[fieldKey].select, elements[fieldKey].category))
                                                                                        setModalProductsIsOpen(true)
                                                                                    }}
                                                                                />}
                                                                        </div>
                                                                    </div>)
                                                                case 'select_provider':
                                                                    return (<div
                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                        key={fieldKey} style={{alignItems: 'end'}}>
                                                                        <div style={{width: '100%'}}
                                                                             className={"form-control"}>
                                                                            <div
                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}
                                                                            </div>
                                                                            <Selector
                                                                                options={providers}
                                                                                value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                onChange={(option) => updateSheet(key, fieldKey,
                                                                                    elements[fieldKey].isMulti ?
                                                                                        Array.from(option, option => parseInt(option.value)) :
                                                                                        parseInt(option.value))}
                                                                                isSearchable={true}
                                                                                isMulti={elements[fieldKey].isMulti ?? false}
                                                                                disabled={!access || ended}
                                                                                isOptionDisabled={(option) => option.disabled}
                                                                            />

                                                                        </div>
                                                                    </div>)
                                                                case 'select_staff':
                                                                    return (<div
                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                        key={fieldKey} style={{alignItems: 'end'}}>
                                                                        <div style={{width: '100%'}}
                                                                             className={"form-control"}>
                                                                            <div
                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}
                                                                            </div>
                                                                            <Selector
                                                                                options={staffs}
                                                                                value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                onChange={(option) => updateSheet(key, fieldKey,
                                                                                    elements[fieldKey].isMulti ?
                                                                                        Array.from(option, option => parseInt(option.value)) :
                                                                                        parseInt(option.value))}
                                                                                isSearchable={true}
                                                                                isMulti={elements[fieldKey].isMulti ?? false}
                                                                                disabled={!access || ended}
                                                                                isOptionDisabled={(option) => option.disabled}
                                                                            />

                                                                        </div>
                                                                    </div>)
                                                                case 'fee':
                                                                    if (sheet && sheet[key] && sheet[key][elements[fieldKey].for] && sheet[key][elements[fieldKey].for].length > 0) {
                                                                        let content = [];
                                                                        // eslint-disable-next-line
                                                                        {
                                                                            (() => {
                                                                                sheet[key][elements[fieldKey].for].forEach(async (staff) => {
                                                                                    content.push(<div
                                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                                        key={fieldKey + '_' + staff}
                                                                                        style={{alignItems: 'end'}}>
                                                                                        <div className={"form-control"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div className={"form-label"}>
                                                                                                {intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})} {staffs.filter((x) => x.value === staff)[0].label}</div>
                                                                                            <div
                                                                                                className={"price-input-container"}>
                                                                                                <p>{intl.formatMessage({id: 'settings.currency.' + event.company.currency})}
                                                                                                </p>
                                                                                                <input type={"number"}
                                                                                                       value={sheet && sheet[key] &&
                                                                                                       sheet[key][fieldKey + '_' + staff] ? sheet[key][fieldKey + '_' + staff] : ''}
                                                                                                       onFocus={(event) => event.target.select()}
                                                                                                       onClick={(event) => event.target.select()}
                                                                                                       min={0}
                                                                                                       disabled={!access || ended}
                                                                                                       onChange={(e) => updateSheet(key, fieldKey + '_' + staff, parseInt(e.target.value))}
                                                                                                       className={"form-input"}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>)
                                                                                })
                                                                            })()
                                                                        }
                                                                        return content
                                                                    } else if (sheet && sheet[key] && sheet[key][elements[fieldKey].for] && typeof sheet[key][elements[fieldKey].for] === 'number') {
                                                                        return (<div
                                                                            className={'d-flex ' + elements[fieldKey].class}
                                                                            key={fieldKey + '_' + sheet[key][elements[fieldKey].for]}
                                                                            style={{alignItems: 'end'}}>
                                                                            <div className={"form-control"} style={{
                                                                                width: '100%',
                                                                                justifyContent: 'end'
                                                                            }}>
                                                                                <div className={"form-label"}>
                                                                                    {intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})} {staffs.filter((x) => x.value === sheet[key][elements[fieldKey].for])[0].label}</div>
                                                                                <div className={"price-input-container"}>
                                                                                    <p>{intl.formatMessage({id: 'settings.currency.' + event.company.currency})}
                                                                                    </p>
                                                                                    <input type={"number"}
                                                                                           value={sheet && sheet[key] &&
                                                                                           sheet[key][fieldKey + '_' + sheet[key][elements[fieldKey].for]] ? sheet[key][fieldKey + '_' + sheet[key][elements[fieldKey].for]] : ''}
                                                                                           onFocus={(event) => event.target.select()}
                                                                                           onClick={(event) => event.target.select()}
                                                                                           min={0}
                                                                                           disabled={!access || ended}
                                                                                           onChange={(e) => updateSheet(key, fieldKey + '_' + sheet[key][elements[fieldKey].for], parseInt(e.target.value))}
                                                                                           className={"form-input"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    } else {
                                                                        return ('')
                                                                    }
                                                                case 'select_from_list':
                                                                    return (<div
                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                        key={fieldKey} style={{alignItems: 'end'}}>
                                                                        <div style={{width: '100%'}}
                                                                             className={"form-control"}>
                                                                            <div
                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}
                                                                            </div>
                                                                            <Selector
                                                                                options={getOptions('list', elements[fieldKey].options)}
                                                                                value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : ''}
                                                                                onChange={(option) => updateSheet(key, fieldKey, option.value)}
                                                                                isSearchable={true}
                                                                                disabled={!access || ended}
                                                                                isOptionDisabled={(option) => option.disabled}
                                                                            />

                                                                        </div>
                                                                    </div>)
                                                                case 'null':
                                                                    return (<div className={elements[fieldKey].class}
                                                                                 key={fieldKey}>
                                                                            <div className={"form-control"}>

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                case 'tab-switch':
                                                                    return (<div
                                                                            className={'d-flex ' + elements[fieldKey].class}
                                                                            key={fieldKey}>

                                                                            <div
                                                                                className={"justify-content-end form-control"}
                                                                                style={{width: '-webkit-fill-available'}}>

                                                                                {
                                                                                    elements[fieldKey].label ?
                                                                                        <div
                                                                                            className={"form-label form-label-switch"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <TabSwitch
                                                                                    value={sheet && sheet[key] && sheet[key][fieldKey] ? sheet[key][fieldKey] : false}
                                                                                    values={elements[fieldKey].values}
                                                                                    onChange={(e) => access && !ended && updateSheet(key, fieldKey, e)}
                                                                                    key1={key}
                                                                                    fieldKey={fieldKey}
                                                                                >

                                                                                </TabSwitch>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                case 'upload':
                                                                    return (<div className={elements[fieldKey].class}
                                                                                 key={fieldKey}>
                                                                            <div className={"form-control"}
                                                                                 style={{width: '-webkit-fill-available'}}>

                                                                                <div
                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                <div className={"tab-content p-2"}
                                                                                     style={{borderRadius: '8px'}}>
                                                                                    <div>
                                                                                        <input type={"file"}
                                                                                               accept=".doc,.docx,.pdf"
                                                                                               ref={inputRef}
                                                                                               style={{display: "none"}}
                                                                                               multiple={false}
                                                                                               onChange={(e) => {
                                                                                                   if (e.target.files[0] && access && !ended) {
                                                                                                       context.setContentUpdated(true)
                                                                                                       setFile(e.target.files[0])
                                                                                                   }
                                                                                               }}
                                                                                        />
                                                                                        <div className={"upload-image-comp"}
                                                                                             onClick={() => access && !ended && inputRef.current.click()}>
                                                                                            {
                                                                                                file !== null ?
                                                                                                    <div
                                                                                                        className={"preview"}>
                                                                                                        <img src={''}
                                                                                                             alt={""}/>
                                                                                                        <div>
                                                                                                            <i style={{fontSize: '2rem'}}
                                                                                                               className="fa-regular fa-file"></i>
                                                                                                            <div
                                                                                                                className={"title"}>{file.name}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :

                                                                                                    <>
                                                                                                        <i className="fa-regular fa-images icon"></i>
                                                                                                        <div
                                                                                                            className={"title"}>{intl.formatMessage({id: "general.upload.excel.title"})}</div>
                                                                                                        <div
                                                                                                            className={"info"}>{intl.formatMessage({id: "general.upload.pdf.info"})}</div>
                                                                                                    </>

                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={"form-control d-block"}>
                                                                                {fileUrl !== '' && <a
                                                                                    className={"btn btn-primary mt-2 d-flex justify-content-center align-items-center"}
                                                                                    type={'button'}
                                                                                    href={fileUrl}
                                                                                    rel={"noreferrer"}
                                                                                    target={"_blank"}
                                                                                ><i className={"fa-solid fa-trash me-2"}/> {intl.formatMessage({id: 'general.buttons.download'})}
                                                                                </a>}
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                case 'input_with_options':
                                                                    if (sheet && sheet[key] && sheet[key][fieldKey] && sheet[key][fieldKey].length > 0) {
                                                                        let content = [];
                                                                        // eslint-disable-next-line
                                                                        {
                                                                            (() => {
                                                                                sheet[key][fieldKey].forEach((item, i) => {
                                                                                    content.push(<div
                                                                                        className={'d-flex ' + elements[fieldKey].class}
                                                                                        key={fieldKey + '_' + i}
                                                                                        style={{alignItems: 'end'}}>
                                                                                        <div className={"form-control me-4"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div
                                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_category'})}</div>
                                                                                            <input type={"text"}
                                                                                                   value={item['category'] ? item['category'] : ''}
                                                                                                   disabled={!access || ended}
                                                                                                   onChange={(e) => {
                                                                                                       let old_value = sheet[key][fieldKey];
                                                                                                       old_value[i]['category'] = e.target.value
                                                                                                       updateSheet(key, fieldKey, old_value)
                                                                                                   }}
                                                                                                   className={"form-input"}
                                                                                                   list="psi_list"
                                                                                            />
                                                                                            <datalist id="psi_list">
                                                                                                {
                                                                                                    elements[fieldKey].options && elements[fieldKey].options.map((option) => (
                                                                                                        <option
                                                                                                            key={option.value}
                                                                                                            value={intl.formatMessage({id: option.label})}/>
                                                                                                    ))
                                                                                                }

                                                                                            </datalist>
                                                                                        </div>
                                                                                        <div className={"form-control"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div
                                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_staff'})}</div>
                                                                                            <Selector
                                                                                                options={staffs}
                                                                                                value={item['staff'] ? item['staff'] : []}
                                                                                                onChange={(option) => {

                                                                                                    let old_value = sheet[key][fieldKey];
                                                                                                    old_value[i]['staff'] = elements[fieldKey].isMulti ?
                                                                                                        Array.from(option, option => parseInt(option.value)) :
                                                                                                        parseInt(option.value)
                                                                                                    updateSheet(key, fieldKey, old_value)
                                                                                                }}
                                                                                                isSearchable={true}
                                                                                                isMulti={elements[fieldKey].isMulti ?? false}
                                                                                                disabled={!access || ended}
                                                                                                isOptionDisabled={(option) => option.disabled}
                                                                                            />
                                                                                        </div>
                                                                                    </div>)
                                                                                })

                                                                                content.push(<Button
                                                                                    className={"btn-primary mb-3"}
                                                                                    value={intl.formatMessage({id: 'events.sheet.' + key + '.new_' + fieldKey})}
                                                                                    type={'button'}
                                                                                    onClick={() => {
                                                                                        let old_value = sheet[key][fieldKey];
                                                                                        old_value[sheet[key][fieldKey].length] = {}
                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                    }}
                                                                                />)
                                                                            })()
                                                                        }
                                                                        return content
                                                                    } else {
                                                                        return (
                                                                            <div className={" border-grey-top"}>
                                                                                <div
                                                                                    className={'d-flex ' + elements[fieldKey].class}
                                                                                    key={fieldKey + '_0'}
                                                                                    style={{alignItems: 'end'}}>
                                                                                    <div className={"form-control me-4"}
                                                                                         style={{
                                                                                             width: '100%',
                                                                                             justifyContent: 'end'
                                                                                         }}>
                                                                                        <div
                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                        <input type={"text"} value={''}
                                                                                               disabled={!access || ended}
                                                                                               onChange={(e) => updateSheet(key, fieldKey, [{category: e.target.value}])}
                                                                                               className={"form-input"}
                                                                                               list="psi_list"
                                                                                        />
                                                                                        <datalist id="psi_list">
                                                                                            {
                                                                                                elements[fieldKey].options && elements[fieldKey].options.map((option) => (
                                                                                                    <option key={option.value}
                                                                                                            value={intl.formatMessage({id: option.label})}/>
                                                                                                ))
                                                                                            }
                                                                                        </datalist>
                                                                                    </div>
                                                                                    <div className={"form-control"} style={{
                                                                                        width: '100%',
                                                                                        justifyContent: 'end'
                                                                                    }}>
                                                                                        <div
                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}</div>
                                                                                        <Selector
                                                                                            options={staffs}
                                                                                            value={[]}
                                                                                            onChange={(option) => updateSheet(key, fieldKey,
                                                                                                [{
                                                                                                    staff: elements[fieldKey].isMulti ?
                                                                                                        Array.from(option, option => parseInt(option.value)) :
                                                                                                        parseInt(option.value)
                                                                                                }])}
                                                                                            isSearchable={true}
                                                                                            isMulti={elements[fieldKey].isMulti ?? false}
                                                                                            disabled={!access || ended}
                                                                                            isOptionDisabled={(option) => option.disabled}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <Button
                                                                                    className={"btn-primary mb-3"}
                                                                                    value={intl.formatMessage({id: 'events.sheet.' + key + '.new_' + fieldKey})}
                                                                                    type={'button'}
                                                                                    onClick={() => {
                                                                                        let old_value = sheet[key][fieldKey];
                                                                                        old_value[0] = {}
                                                                                        old_value[1] = {}
                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                    }}
                                                                                />
                                                                            </div>)
                                                                    }
                                                                case 'select_activation':
                                                                    if (sheet && sheet[key] && sheet[key][fieldKey] && sheet[key][fieldKey].length > 0) {
                                                                        let content = [];
                                                                        // eslint-disable-next-line
                                                                        {
                                                                            (() => {
                                                                                sheet[key][fieldKey].forEach((item, i) => {
                                                                                    content.push(<>
                                                                                        <div
                                                                                            className={'d-flex ' + elements[fieldKey].class}
                                                                                            key={fieldKey + '_' + i}
                                                                                            style={{alignItems: 'end'}}>
                                                                                            <div className={"form-control "}
                                                                                                 style={{
                                                                                                     width: '100%',
                                                                                                     justifyContent: 'end'
                                                                                                 }}>
                                                                                                <div
                                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_item'})}</div>
                                                                                                <Selector
                                                                                                    options={activations}
                                                                                                    value={item['item'] ? item['item'] : []}
                                                                                                    onChange={(option) => {
                                                                                                        let old_value = sheet[key][fieldKey];
                                                                                                        old_value[i]['item'] = parseInt(option.value)
                                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                                    }}
                                                                                                    isSearchable={true}
                                                                                                    isMulti={elements[fieldKey].isMulti ?? false}
                                                                                                    disabled={!access || ended}
                                                                                                    isOptionDisabled={(option) => option.disabled}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={'d-flex '}
                                                                                             style={{alignItems: 'end'}}>
                                                                                            <div
                                                                                                className={"form-control me-4"}
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                    justifyContent: 'end'
                                                                                                }}>
                                                                                                <div
                                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_staff'})}</div>
                                                                                                <Selector
                                                                                                    options={staffs}
                                                                                                    value={item['staff'] ? item['staff'] : []}
                                                                                                    onChange={(option) => {

                                                                                                        let old_value = sheet[key][fieldKey];
                                                                                                        old_value[i]['staff'] = parseInt(option.value)
                                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                                    }}
                                                                                                    isSearchable={true}
                                                                                                    isMulti={elements[fieldKey].isMulti ?? false}
                                                                                                    disabled={!access || ended}
                                                                                                    isOptionDisabled={(option) => option.disabled}
                                                                                                />
                                                                                            </div>
                                                                                            <div className={"form-control"}
                                                                                                 style={{
                                                                                                     width: '100%',
                                                                                                     justifyContent: 'end'
                                                                                                 }}>
                                                                                                <div
                                                                                                    className={"form-label"}>
                                                                                                    {intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_staff_fee'})}</div>
                                                                                                <div
                                                                                                    className={"price-input-container"}>
                                                                                                    <p>{intl.formatMessage({id: 'settings.currency.' + event.company.currency})}
                                                                                                    </p>
                                                                                                    <input type={"number"}
                                                                                                           value={item['staff_fee'] ?? ''}
                                                                                                           onFocus={(event) => event.target.select()}
                                                                                                           onClick={(event) => event.target.select()}
                                                                                                           min={0}
                                                                                                           disabled={!access || ended}
                                                                                                           onChange={(e) => {

                                                                                                               let old_value = sheet[key][fieldKey];
                                                                                                               old_value[i]['staff_fee'] = parseInt(e.target.value)
                                                                                                               updateSheet(key, fieldKey, old_value)
                                                                                                           }}
                                                                                                           className={"form-input"}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={'d-flex mb-2'}
                                                                                             key={fieldKey}>

                                                                                            <div
                                                                                                className={"justify-content-end form-control"}
                                                                                                style={{width: '-webkit-fill-available'}}>
                                                                                                <div
                                                                                                    className={"form-label form-label-switch"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_made_by'})}</div>
                                                                                                <TabSwitch
                                                                                                    value={item['made_by'] ?? 'salon'}
                                                                                                    values={{
                                                                                                        'false': 'salon',
                                                                                                        'true': 'provider',
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        if (access && !ended) {
                                                                                                            let old_value = sheet[key][fieldKey];
                                                                                                            old_value[i]['made_by'] = e
                                                                                                            updateSheet(key, fieldKey, old_value)
                                                                                                        }
                                                                                                    }}
                                                                                                    key1={key}
                                                                                                    fieldKey={fieldKey}
                                                                                                >

                                                                                                </TabSwitch>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>)
                                                                                    if (item['made_by'] === 'provider') {
                                                                                        content.push(<div
                                                                                            className={'d-flex mb-2'}
                                                                                            key={fieldKey}
                                                                                            style={{alignItems: 'end'}}>
                                                                                            <div style={{width: '100%'}}
                                                                                                 className={"form-control"}>
                                                                                                <div
                                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '.provider'})}
                                                                                                </div>
                                                                                                <Selector
                                                                                                    options={providers}
                                                                                                    value={item['provider'] ?? ''}
                                                                                                    onChange={(option) => {
                                                                                                        if (access && !ended) {
                                                                                                            let old_value = sheet[key][fieldKey];
                                                                                                            old_value[i]['provider'] = parseInt(option.value)
                                                                                                            updateSheet(key, fieldKey, old_value)
                                                                                                        }
                                                                                                    }}
                                                                                                    isSearchable={true}
                                                                                                    isMulti={elements[fieldKey].isMulti ?? false}
                                                                                                    disabled={!access || ended}
                                                                                                    isOptionDisabled={(option) => option.disabled}
                                                                                                />

                                                                                            </div>
                                                                                        </div>)
                                                                                    }
                                                                                })

                                                                                content.push(<Button
                                                                                    className={"btn-primary mb-3"}
                                                                                    value={intl.formatMessage({id: 'events.sheet.' + key + '.new_' + fieldKey})}
                                                                                    type={'button'}
                                                                                    onClick={() => {
                                                                                        let old_value = sheet[key][fieldKey];
                                                                                        old_value[sheet[key][fieldKey].length] = {}
                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                    }}
                                                                                />)
                                                                            })()
                                                                        }
                                                                        return content
                                                                    } else {
                                                                        return (
                                                                            <div className={" border-grey-top"}>
                                                                                <div
                                                                                    className={'d-flex ' + elements[fieldKey].class}
                                                                                    key={fieldKey + '_0'}
                                                                                    style={{alignItems: 'end'}}>
                                                                                    <div className={"form-control me-4"}
                                                                                         style={{
                                                                                             width: '100%',
                                                                                             justifyContent: 'end'
                                                                                         }}>
                                                                                        <div
                                                                                            className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_item'})}</div>
                                                                                        <Selector
                                                                                            options={activations}
                                                                                            value={[]}
                                                                                            onChange={(option) => {
                                                                                                updateSheet(key, fieldKey, [{item: parseInt(option.value)}])
                                                                                            }}
                                                                                            isSearchable={true}
                                                                                            isMulti={elements[fieldKey].isMulti ?? false}
                                                                                            disabled={!access || ended}
                                                                                            isOptionDisabled={(option) => option.disabled}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <Button
                                                                                    className={"btn-primary mb-3"}
                                                                                    value={intl.formatMessage({id: 'events.sheet.' + key + '.new_' + fieldKey})}
                                                                                    type={'button'}
                                                                                    onClick={() => {
                                                                                        let old_value = sheet[key][fieldKey];
                                                                                        old_value[0] = {}
                                                                                        old_value[1] = {}
                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                    }}
                                                                                />
                                                                            </div>)
                                                                    }
                                                                case 'button':
                                                                    return (<div className={elements[fieldKey].class}
                                                                                 key={fieldKey}>

                                                                            <div
                                                                                className={"justify-content-end form-control"}
                                                                                style={{width: '-webkit-fill-available'}}>
                                                                                {
                                                                                    Array.isArray(sheet[key][fieldKey]) && sheet[key][fieldKey].map((tax) => {
                                                                                        let curentItem = sheet && sheet[key] && sheet[key][fieldKey] &&
                                                                                        sheet[key][fieldKey].filter((item) => item.id === tax.id).length > 0 ?
                                                                                            sheet[key][fieldKey].find((item) => item.id === tax.id) : [];

                                                                                        return (
                                                                                            <div className={'d-flex'}
                                                                                                 key={tax.id}>
                                                                                                <div className={"form-control"}
                                                                                                     style={{
                                                                                                         width: '100%',
                                                                                                         justifyContent: 'end'
                                                                                                     }}>
                                                                                                    <div
                                                                                                        className={"form-label"}>{tax.label}</div>
                                                                                                    <div
                                                                                                        className={"price-input-container"}>
                                                                                                        <p>{tax.percent ? '%' :intl.formatMessage({id: 'settings.currency.' + event.company.currency})}
                                                                                                        </p>
                                                                                                        <input type={"number"}
                                                                                                               disabled={!access || ended}
                                                                                                               value={curentItem.price ?? ''}
                                                                                                               onFocus={(event) => event.target.select()}
                                                                                                               onClick={(event) => event.target.select()}
                                                                                                               min={0}
                                                                                                               onChange={(e) => {
                                                                                                                   let currentTaxes = sheet[currentKey][currentFieldKey] ?? [];
                                                                                                                   var index = currentTaxes.indexOf(curentItem);
                                                                                                                   if (index !== -1) {
                                                                                                                       currentTaxes.splice(index, 1);
                                                                                                                   }
                                                                                                                   curentItem.price = e.target.value

                                                                                                                   updateSheet(key, fieldKey, [curentItem].concat(currentTaxes))
                                                                                                               }}
                                                                                                               className={"form-input"}


                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })

                                                                                }

                                                                                <Button
                                                                                    className={"btn-primary"}
                                                                                    value={intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey})}
                                                                                    type={'button'}
                                                                                    onClick={() => {
                                                                                        setCurrentKey(key)
                                                                                        setCurrentFieldKey(fieldKey)
                                                                                        setModalContent(typeof elements[fieldKey].content === 'object' ? elements[fieldKey].content :
                                                                                            getOptions(elements[fieldKey].content, event.event_type))
                                                                                        setModalIsOpen(true)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                case 'product_btl':
                                                                    let content = [];
                                                                    if (sheet && sheet[key] && sheet[key][fieldKey] && sheet[key][fieldKey].length > 0) {

                                                                        // eslint-disable-next-line
                                                                        {
                                                                            (() => {
                                                                                sheet[key][fieldKey].forEach((item, i) => {
                                                                                    content.push(<div
                                                                                        className={'d-flex border-grey-top ' + elements[fieldKey].class}
                                                                                        key={fieldKey}
                                                                                        style={{alignItems: 'end'}}>
                                                                                        <div className={"form-control me-4"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div
                                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_item'})}
                                                                                            </div>
                                                                                            <Selector
                                                                                                options={providers}
                                                                                                value={item['item'] ?? ''}
                                                                                                onChange={(option) => {

                                                                                                    let old_value = sheet[key][fieldKey];
                                                                                                    old_value[i]['item'] = parseInt(option.value)
                                                                                                    updateSheet(key, fieldKey, old_value)
                                                                                                }}
                                                                                                isSearchable={true}
                                                                                                isMulti={elements[fieldKey].isMulti ?? false}
                                                                                                disabled={!access || ended}
                                                                                                isOptionDisabled={(option) => option.disabled}
                                                                                            />

                                                                                        </div>
                                                                                        <div className={"form-control"}
                                                                                             style={{
                                                                                                 width: '100%',
                                                                                                 justifyContent: 'end'
                                                                                             }}>
                                                                                            <div
                                                                                                className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_qty'})}</div>
                                                                                            <input type={"number"}
                                                                                                   value={item['qty'] ?? ''}
                                                                                                   onFocus={(event) => event.target.select()}
                                                                                                   onClick={(event) => event.target.select()}
                                                                                                   min={0}
                                                                                                   disabled={!access || ended}
                                                                                                   onChange={(e) => {
                                                                                                       let old_value = sheet[key][fieldKey];
                                                                                                       old_value[i]['qty'] = parseInt(e.target.value)
                                                                                                       updateSheet(key, fieldKey, old_value)
                                                                                                   }}
                                                                                                   className={"form-input"}
                                                                                            />
                                                                                        </div>
                                                                                    </div>)

                                                                                    if (item['item']) {
                                                                                        content.push(<div
                                                                                            className={'d-flex ' + elements[fieldKey].class}
                                                                                            style={{alignItems: 'end'}}>
                                                                                            <div style={{width: '100%'}}
                                                                                                 className={"form-control"}>
                                                                                                <div
                                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_delivery_by'})}
                                                                                                </div>
                                                                                                <Selector
                                                                                                    options={getOptions('list', elements[fieldKey].options)}
                                                                                                    value={item['delivery_by'] ?? ''}
                                                                                                    onChange={(option) => {
                                                                                                        let old_value = sheet[key][fieldKey];
                                                                                                        old_value[i]['delivery_by'] = option.value
                                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                                    }}
                                                                                                    isSearchable={true}
                                                                                                    disabled={!access || ended}
                                                                                                    isOptionDisabled={(option) => option.disabled}
                                                                                                />

                                                                                            </div>
                                                                                        </div>)
                                                                                        if (item['delivery_by'] === 'provider') {
                                                                                            content.push(<div
                                                                                                className={'d-flex ' + elements[fieldKey].class}
                                                                                                style={{alignItems: 'end'}}>
                                                                                                <div style={{width: '100%'}}
                                                                                                     className={"form-control"}>
                                                                                                    <div
                                                                                                        className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_provider'})}
                                                                                                    </div>
                                                                                                    <Selector
                                                                                                        options={providers}
                                                                                                        value={item['provider'] ?? ''}
                                                                                                        onChange={(option) => {
                                                                                                            let old_value = sheet[key][fieldKey];
                                                                                                            old_value[i]['provider'] = parseInt(option.value)
                                                                                                            updateSheet(key, fieldKey, old_value)
                                                                                                        }}
                                                                                                        isSearchable={true}
                                                                                                        disabled={!access || ended}
                                                                                                        isOptionDisabled={(option) => option.disabled}
                                                                                                    />

                                                                                                </div>
                                                                                            </div>)
                                                                                        }
                                                                                        content.push(<div
                                                                                            className={elements[fieldKey].class}
                                                                                        >
                                                                                            <div className={"form-control"}>
                                                                                                <div
                                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_hour'})}</div>
                                                                                                <DatePicker
                                                                                                    timeIntervals={15}
                                                                                                    onKeyDown={(e) => {
                                                                                                        e.preventDefault();
                                                                                                    }}
                                                                                                    timeCaption={intl.formatMessage({id: 'events.time'})}
                                                                                                    selected={item['hour'] ? new Date(item['hour']) : ''}
                                                                                                    onChange={(date) => {
                                                                                                        let old_value = sheet[key][fieldKey];
                                                                                                        old_value[i]['hour'] = date
                                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                                    }}
                                                                                                    minTime={moment(timeStart).startOf('day').toDate()}
                                                                                                    maxTime={moment(timeStart).toDate()}
                                                                                                    showTimeSelectOnly
                                                                                                    showTimeSelect
                                                                                                    dateFormat={"HH:mm"}
                                                                                                    timeFormat="HH:mm"
                                                                                                    disabled={!access || ended}
                                                                                                    className={"form-input"}/>

                                                                                            </div>
                                                                                        </div>)

                                                                                        content.push(
                                                                                            <div
                                                                                                className={'d-flex ' + elements[fieldKey].class}
                                                                                                style={{alignItems: 'end'}}>
                                                                                                <div
                                                                                                    className={"form-control me-4"}
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        justifyContent: 'end'
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_person'})}</div>
                                                                                                    <input type={"text"}
                                                                                                           value={item['person'] ?? ''}
                                                                                                           disabled={!access || ended}
                                                                                                           onChange={(e) => {
                                                                                                               let old_value = sheet[key][fieldKey];
                                                                                                               old_value[i]['person'] = e.target.value
                                                                                                               updateSheet(key, fieldKey, old_value)
                                                                                                           }}
                                                                                                           className={"form-input"}
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className={"form-control"}
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        justifyContent: 'end'
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_phone'})}</div>
                                                                                                    <input type={"text"}
                                                                                                           value={item['phone'] ?? ''}
                                                                                                           disabled={!access || ended}
                                                                                                           onChange={(e) => {
                                                                                                               let old_value = sheet[key][fieldKey];
                                                                                                               old_value[i]['phone'] = e.target.value
                                                                                                               updateSheet(key, fieldKey, old_value)
                                                                                                           }}
                                                                                                           className={"form-input"}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                        content.push(
                                                                                            <div
                                                                                                className={'d-flex ' + elements[fieldKey].class}
                                                                                                style={{alignItems: 'end'}}>
                                                                                                <div
                                                                                                    className={"form-control"}
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        justifyContent: 'end'
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_details'})}</div>
                                                                                                    <input type={"text"}
                                                                                                           value={item['details'] ?? ''}
                                                                                                           disabled={!access || ended}
                                                                                                           onChange={(e) => {
                                                                                                               let old_value = sheet[key][fieldKey];
                                                                                                               old_value[i]['details'] = e.target.value
                                                                                                               updateSheet(key, fieldKey, old_value)
                                                                                                           }}
                                                                                                           className={"form-input"}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )


                                                                                    }
                                                                                })

                                                                                content.push(<Button
                                                                                    className={"btn-primary mb-3"}
                                                                                    value={intl.formatMessage({id: 'events.sheet.' + key + '.new_' + fieldKey})}
                                                                                    type={'button'}
                                                                                    onClick={() => {
                                                                                        let old_value = sheet[key][fieldKey];
                                                                                        old_value[sheet[key][fieldKey].length] = {}
                                                                                        updateSheet(key, fieldKey, old_value)
                                                                                    }}
                                                                                />)
                                                                            })()
                                                                        }
                                                                        return content
                                                                    } else {
                                                                        content.push(<div
                                                                            className={'d-flex border-grey-top ' + elements[fieldKey].class}
                                                                            key={fieldKey} style={{alignItems: 'end'}}>
                                                                            <div className={"form-control"} style={{
                                                                                width: '100%',
                                                                                justifyContent: 'end'
                                                                            }}>
                                                                                <div
                                                                                    className={"form-label"}>{intl.formatMessage({id: 'events.sheet.' + key + '.' + fieldKey + '_item'})}
                                                                                </div>
                                                                                <Selector
                                                                                    options={providers}
                                                                                    value={''}
                                                                                    onChange={(option) => {
                                                                                        updateSheet(key, fieldKey, [{item: parseInt(option.value)}])
                                                                                    }}
                                                                                    isSearchable={true}
                                                                                    isMulti={elements[fieldKey].isMulti ?? false}
                                                                                    disabled={!access || ended}
                                                                                    isOptionDisabled={(option) => option.disabled}
                                                                                />
                                                                            </div>
                                                                        </div>)

                                                                        return content
                                                                    }
                                                                default:
                                                                    break
                                                            }
                                                        }

                                                    }
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={"footer pb-1 pt-1"}>
                                        {access && !ended && <div className={"d-flex"}><Button
                                            className={"btn-secondary mb-2 ms-auto"}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={intl.formatMessage({id: 'general.buttons.save'})}
                                            //disabled={saveLoading}
                                            // loading={saveLoading}
                                            type={'submit'}
                                        /></div>}


                                    </div>

                                </form>}
                        </div>
                    )
                })
            }

            {
                modalIsOpen &&

                <ModalComp
                    title={intl.formatMessage({id: 'events.sheet.' + currentKey + '.' + currentFieldKey})}
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    className={"subscription-limit"}
                >
                    {
                        currentKey && currentFieldKey && modalContent &&
                        <div className={"content"}>
                            {

                                /*eslint-disable */
                                //suppress all warnings between comments
                                Object.keys(modalContent).map(fieldKey => {
                                    /*eslint-enable */
                                    if (currentFieldKey === 'add_taxes') {
                                        let curentItem = sheet && sheet[currentKey] && sheet[currentKey][currentFieldKey] &&
                                        sheet[currentKey][currentFieldKey].filter((item) => item.id === modalContent[fieldKey].value).length > 0 ?
                                            sheet[currentKey][currentFieldKey].find((item) => item.id === modalContent[fieldKey].value) : [];
                                        return (<div className={"col-12"}
                                                     key={modalContent[fieldKey].value}>
                                            <div className={"form-control"}>
                                                <label className={"form-label"}>
                                                    <input type={"checkbox"}
                                                           disabled={!access || ended}
                                                           checked={curentItem.id ? true : false}
                                                           onChange={(e) => {
                                                               let currentTaxes = sheet[currentKey][currentFieldKey] ?? [];
                                                               if (curentItem.id) {
                                                                   var index = currentTaxes.indexOf(curentItem);
                                                                   if (index !== -1) {
                                                                       currentTaxes.splice(index, 1);
                                                                   }
                                                                   updateSheet(currentKey, currentFieldKey, currentTaxes)
                                                               } else {

                                                                   updateSheet(currentKey, currentFieldKey,
                                                                       [{
                                                                           id: modalContent[fieldKey].value,
                                                                           type: modalContent[fieldKey].type,
                                                                           percent: modalContent[fieldKey].percent,
                                                                           percent_of: modalContent[fieldKey].percent_of,
                                                                           label: modalContent[fieldKey].label + ' (' + intl.formatMessage({id: 'settings.taxes.type.' + modalContent[fieldKey].type}) + ')',
                                                                           value: curentItem.value ? !curentItem.value : true
                                                                       }].concat(sheet[currentKey][currentFieldKey] ?? []))
                                                               }


                                                           }}/>
                                                    {modalContent[fieldKey].label} ( {intl.formatMessage({id: 'settings.taxes.type.' + modalContent[fieldKey].type})})</label>

                                            </div>
                                        </div>)
                                    } else {
                                        if (displayField(modalContent[fieldKey], currentKey)) {
                                            switch (modalContent[fieldKey].type) {
                                                case 'checkbox':
                                                    return (<div className={modalContent[fieldKey].class}
                                                                 key={fieldKey}>
                                                        <div className={"form-control"}>
                                                            <label className={"form-label"}>
                                                                <input type={"checkbox"}
                                                                       disabled={!access || ended}
                                                                       checked={sheet && sheet[currentKey] && sheet[currentKey][fieldKey] ? sheet[currentKey][fieldKey] : false}
                                                                       onChange={(e) => updateSheet(currentKey, fieldKey, e.target.checked)}/>
                                                                {intl.formatMessage({id: 'events.sheet.' + currentKey + '.' + fieldKey})}
                                                            </label>

                                                        </div>
                                                    </div>)
                                                default:
                                                    break
                                            }
                                        }
                                    }
                                })
                            }
                        </div>
                    }
                    {
                        currentKey && currentFieldKey && modalContent &&
                        <div className={"footer d-flex"}>
                            {access && !ended && <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                onClick={() => {
                                    setModalIsOpen(false)
                                }
                                }
                            />}

                        </div>
                    }
                </ModalComp>
            }

            <ModalComp
                title={intl.formatMessage({id: 'events.sheet.product'})}
                isOpen={modalProductsIsOpen}
                onRequestClose={() => setModalProductsIsOpen(false)}
                className={"subscription-limit product-modal"}
            >
                {
                    currentKey && currentFieldKey &&
                    <div className={"content"}>
                        <div className={"row"}>
                            {
                                currentProducts.length > 0 ?
                                    currentProducts.map((product) => (

                                        <div className={"col-6"} key={product.value}>

                                            <div
                                                className={"product-icon-picker " + (sheet && sheet[currentKey] && sheet[currentKey][currentFieldKey]
                                                && parseInt(sheet[currentKey][currentFieldKey]) === parseInt(product.value) ? 'active' : '')}
                                                onClick={() => {
                                                    updateSheet(currentKey, currentFieldKey, parseInt(product.value))
                                                    updateSheet(currentKey, currentFieldKey + '_vat', parseInt(product.vat))
                                                }}>
                                                <div className={"product-icon"}>
                                                    <img
                                                        src={process.env.REACT_APP_API + (product.image ? product.image.url : '/no_img.jpg')}
                                                        alt={""}/>

                                                    <div className={"title"}>
                                                        {product.label}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className={"col-12"}>
                                        <div className={"alert alert-warning"}>
                                            {intl.formatMessage({id: 'products.alert_no_products'})}
                                        </div>
                                        <Button
                                            className={"btn-primary d-flex align-items-center justify-content-center mt-3"}
                                            style={{width: '100%'}}
                                            icon={"fa-solid fa-plus"}
                                            value={intl.formatMessage({id: 'general.buttons.add'})}
                                            onClick={() => navigate('/products/add')}
                                        />
                                    </div>
                            }

                        </div>

                    </div>
                }
                {
                    currentKey && currentFieldKey && currentProducts.length > 0 &&
                    <div className={"footer d-flex"}>
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-regular fa-floppy-disk"}
                            value={intl.formatMessage({id: 'general.buttons.save'})}
                            onClick={() => setModalProductsIsOpen(false)}
                        />

                    </div>
                }
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'events.include_in_doc'})}
                isOpen={modalExportDoc}
                onRequestClose={() => setModalExportDoc(false)}
                className={"subscription-limit product-modal"}
            >
                    <div className={"content"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <Selector
                                    options={[{
                                        value: 'food',
                                        label: intl.formatMessage({id: 'events.event_food_menu'})
                                    },{
                                        value: 'drink',
                                        label: intl.formatMessage({id: 'events.event_drink_menu'})
                                    },{
                                        value: 'logistics',
                                        label: intl.formatMessage({id: 'events.menu.logistics'})
                                    },{
                                        value: 'staff',
                                        label: intl.formatMessage({id: 'events.menu.staff'})
                                    }]}
                                    value={includeInDoc}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setIncludeInDoc(Array.from(option, option => option.value))
                                    }}
                                    isSearchable={true}
                                    isMulti
                                    hideSelectedOptions={false}
                                    isOptionDisabled={(option) => option.disabled}
                                    isClearable={false}
                                />
                            </div>
                        </div>
                    </div>


                    <div className={"footer d-flex"}>
                        <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-regular fa-floppy-disk"}
                            value={intl.formatMessage({id: 'general.buttons.download_doc'})}
                            onClick={() => exportSheetDoc()}
                        />

                    </div>
            </ModalComp>
        </div>
    );
}
