import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";

export default function ExportLogistics() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [loadingDownload, setLoadingDownload] = useState(false)

    const exportExcel = () => {
        setLoadingDownload(true)
        axios.post(process.env.REACT_APP_API + '/event-logistics-excel/' + params.id, {language: localStorage.getItem('language') ?? 'ro'}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }})      .then(response => {
            console.log(response)
            if (window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'openLink',
                    url: response.data.file  ,
                }))
            }else{
                var a = document.createElement('a');
                a.href = response.data.file;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }
            setLoadingDownload(false)
        })
            .catch(e => console.log(e))
    }
    return (
        <Button
            className={"btn-secondary mb-2 w-100"}
            icon={"fa-regular fa-floppy-disk"}
            value={intl.formatMessage({id: 'general.download_logistics_excel'})}
            type={"button"}

            loading={loadingDownload}
            disabled={loadingDownload}
            onClick={() => exportExcel()}
        />
    );
}

